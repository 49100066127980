window.addEventListener('load', (event) => {
  // 三点リーダー
  $(document).on('click', function (e) {
    // クリックされた場所の判定
    if (!$(e.target).closest('.tweet-show-menu').length && !$(e.target).closest('.point-leader').length) {
      $('.tweet-show-menu').hide();
    } else if ($(e.target).closest('.point-leader').length) {
      // モーダルの表示状態の判定
      if ($(e.target).children('.tweet-show-menu').is(':hidden')) {
        $(e.target).children('.tweet-show-menu').show();
      } else {
        $(e.target).children('.tweet-show-menu').hide();
      }
    }
  });
});